module.exports = ({
    
    "SM321.TITLE":"権限設定",
    "TYPE.SUCCESS": "SUCCESS",
    "SM321.COMMSG001": "{type}が保存されました。",
    "SM321.LABEL.SEARCH.NAME":"権限",
    "SM321.LABEL.SEARCH.CATEGORY":"カテゴリー",
    "SM321.LABEL.SEARCH.STATUS":"ステータス",
    "SM321.TABLE.HEAD_NAME":"権限",
    "SM321.TABLE.HEAD_CATEGORY":"カテゴリー",
    "SM321.TABLE.HEAD_DESCRIPTION":"詳細",
    "SM321.TABLE.HEAD_AUDIT":"Audit",
    "SM321.PLACEHOLDER.SEARCH.NAME":"権限を入力してください",

    "SM321.OPTION.STATUS.ALL":"すべて",
    "SM321.OPTION.STATUS.ENABLE":"有効",
    "SM321.OPTION.STATUS.DISABLE":"無効",

    "SM321.LABEL.BUTTON.SEARCH":"検索",
    "SM321.LABEL.BUTTON.CLEAR":"条件クリア",
    "SM321.LABEL.BUTTON.SAVE":"保存",
    "SM321.LABEL.BUTTON.IMPORT":"取り込み",
    "SM321.LABEL.BUTTON.EXPORT":"ダウンロード",

    "UPLOAD.POPUP.TITLE": "Excelファイルの取り込み",
    "UPLOAD.POPUP.REQUIRED":"必須",
    "UPLOAD.POPUP.FILE.BOX": "ファイル",
    "UPLOAD.POPUP.PLACEHOLDER": "ファイルを選択してください",
    "UPLOAD.POPUP.SELECT.FILE": "選択",
    "UPLOAD.POPUP.DO.UPLOAD": "取り込み",
    "UPLOAD.POPUP.DO.CANCEL": "キャンセル",

    "SM504MSG002": "取り込みが完了しました。",

    "SM420MSG001": "入力間隔は、入力期間以下である必要があります。",
    "SM420MSG002": "入力期間は、入力間隔以上である必要があります。",
    "SM420MSG003": "スケジュールは設定されていません。",
    
});
