module.exports = ({
    'SM440P001': 'Property Data',
    'SM440MSG001': 'This Property cannot be set to items of a Normal Page.',
    'SM440MSG002': 'EXCLUDE and SCRNUM cannot be set to a same item.',
    'SM440MSG003': 'SCRNUM can only be set to items of Normal Block.',
    'SM440MSG004': 'ENRNUM and SCRNUM cannot be set to a same item.',
    'SM440MSG005': 'Multiple ENRNUM cannot be set to a same page.',
    'SM440MSG006': 'ENRNUM can only be set to TEXT item.',
    'SM440MSG007': 'ENRNUM can only be set to items of Normal Block."',
    'SM440MSG008': 'Are you sure you want to delete this Property?"',
    'SM440MSG009': 'The Visit ID you entered is already in use.',
    'SM440MSG010': 'Registration Page type has been set to more than one page.',
    'SM440MSG011': 'Multiple SCRNUMs cannot be set on the same page.',
    'SM440MSG012': 'SCRNUM can only be set to TEXT item.',
    'SM440MSG013': 'ePRO TRIGGER can only be set to Date (YYYY/MM/DD) item.',
    'SM440MSG014': 'ePRO TRIGGER can only be set to items of Normal Block.',
    'SM440MSG015': 'This Property cannot be set to LABEL or NOW NO items.',
    'SM440MSG016': 'This Property can only be set to TEXT or TEXT AREA items.',
    'SM440MSG017': 'This Property cannot be set to LABEL or NOW NO items.',
    'SM440MSG018': 'RANDOM_KEY cannot be set to more than one item.',
    'SM440MSG019': 'One Stratification Group No. can only be set to one item.',
    'SM440MSG020': 'Multiple Stratification Group No. cannot be set to a same item.',
    'SM440MSG021': 'Multiple RANDOM_KEY cannot be set to a same item.',
    'SM440MSG022': 'ARM_GENERATE and IP_GENERATE cannot be set to a same item.',
    'SM440MSG023': 'HIDDEN and SCRNUM cannot be set to a same item.',
    'SM440MSG024': 'HIDDEN and ENRNUM cannot be set to a same item.',
    'SM440MSG025': 'STRATIFICATION can only be set to items of Normal Block.',
    'SM440MSG026': 'CONSENT DATE can only be set to DATE item.',
    'SM440BTN001': 'CRF Page"',
    'SM440LB001': 'Version"',
    'SM440LB002': 'Disable input',
    'SM440LB003': 'Set default value',
    'SM440LB004': 'Unit is set to the above of item',
    'SM440LB005': 'Unit is set to the below of item',
    'SM440LB006': 'Unit is set to the left of item',
    'SM440LB007': 'Unit is set to the right of item',
    'SM440LB008': 'Exclude only specified visit',
    'SM440LB009': 'Enter screening number',
    'SM440LB010': 'Enter enroll number',
    'SM440LB011': 'Enter Randomize Key',
    'SM440LB012': 'Store ARM Value',
    'SM440LB013': 'Store IP No. Value',
    'SM440LB014': 'Set Stratification Group No.',
    'SM440LB015': 'Make item be hidden',
    'SM440LB020': 'Enter registration date',
    'SM440LB021': 'ePROを有効にする',
    'SM440LB016': 'Text',
    'SM440LB017': 'VISIT ID',
    'SM440LB018': 'Number',
    'SM440LB019': 'Y/N',
    'SM440LB022': 'CRF Group ID',
    'SM440LB023': 'Get data from the device',

    'SM440H001': 'Page ID',
    'SM440H002': 'Block ID',
    'SM440H003': 'item ID',
    'SM440H004': 'Property Type',
    'SM440H005': 'Property Data',
    'SM440H006': 'Delete',
    'SM440TT001': 'PROPERTY',
    'SM440OPT001': 'AUTOFILL',
    'SM440OPT002': 'DEFAULT',
    'SM440OPT003': 'UNIT_UPPER',
    'SM440OPT004': 'UNIT_BOTTOM',
    'SM440OPT005': 'UNIT_LEFT',
    'SM440OPT006': 'UNIT_RIGHT',
    'SM440OPT007': 'EXCLUDE',
    'SM440OPT008': 'SCRNUM',
    'SM440OPT009': 'ENRNUM',
    'SM440OPT010': 'RANDOM_KEY',
    'SM440OPT011': 'ARM_GENERATE',
    'SM440OPT012': 'IP_GENERATE',
    'SM440OPT013': 'STRATIFICATION',
    'SM440OPT014': 'HIDDEN',
    'SM440OPT015': 'CONSENT DATE',
    'SM440OPT016': 'ePRO TRIGGER',
    'SM440OPT017': 'GET_DEVICE_DATA',
});