module.exports = ({
    "CRFGROUP.TITLE": "CRFグループ",
    "CRFGROUP.TEXTID": "グループID",
    "CRFGROUP.TEXTID.PLACEHOLDER": "グループID",
    "CRFGROUP.LABEL": "ラベル",
    "CRFGROUP.TYPE": "種別",
    "CRFGROUP.DELETE": "削除",
    "CRFGROUP.AUDIT": "Audit",
    "CRFGROUP.MODAL.TITLE": "CRFグループを回復したら全ての",
    "CRFGROUP.MODAL.CONTENT.RESTORE": "CRFグループを回復したら全てのCRFページも回復されます。\n よろしいですか。",
    "CRFGROUP.MODAL.CONTENT.DELETE": "CRFグループを削除したら全てのCRFページも削除されます。\nよろしいですか。",
    "CRFGROUP.SUCCESS.TITLE": "CRFグル",
    "CRFGROUP.SUCCESS.CONTENT": "CRFグループが保存されました。",
    "CRFGROUP.TEXTID.MESSAGE.REQUIRED": "グループIDを入力してください。",
    "CRFGROUP.LABEL.MESSAGE.REQUIRED": "ラベルを入力",
    "CRFGROUP.LABEL.PLACEHOLDER": "ラベル",
    "CRFGROUP.MESSAGE.DUPLICATE": "入力されたグループIDがすでに使用されています。",
    //audit
    "CRFGROUP.AUDIT.TABLE.HEADER.NO": "No.",
    "CRFGROUP.AUDIT.TABLE.HEADER.SEQ_NO": "Seq No.",
    "CRFGROUP.AUDIT.TABLE.HEADER.UPD_USER": "更新者",
    "CRFGROUP.AUDIT.TABLE.HEADER.UPD_DATE": "更新日時",
    "CRFGROUP.AUDIT.TITLE": "の編集履歴",
    "CRFGROUP.TEXT.BOTTOM": "※は必須項目です。",
    "CRFGROUP.AUDIT.TITLE.DEFAULT": "グループ",
    //import
    "SM435MSG004": "{sheet_name}シートに{n}行目：{item}が必須です。",
    "SM435MSG005": "{sheet_name}シートに{n}行目：{item}に入力したデータが正しくありません。",
    "SM435MSG006": "{sheet_name}シートに{n}行目：{item}の長さが{max_length}以下入力してください。",
    "SM435MSG007": "{sheet_name}シートに{n}行目：入力した{item}が存在しません。",
    //SM435MSG008 = SM435MSG009
    "SM435MSG008": "{sheet_name}シートに{n}行目：入力データが重複されました。",
    "SM435MSG009": "{sheet_name}シートに{n}行目：最大値が最小値より大きい数値を入力します。",
    "SM433MSG011": "{sheet_name}シートに{n}行目：Code Listに設定されているコード値の桁数より小さい値が設定されています。",
    "SM435MSG018": "{sheet_name}シートに{n}行目：{item}が存在しません。",
    "SM435MSG019": "{sheet_name}シートに{n}行目：{item}の{block_id}が存在しません。",
});