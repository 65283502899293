module.exports = ({
    "PROPERTY.FORMAT.ERROR":"入力したデータが正しくありません。",

    'SM440P001': 'Property Data',
    'SM440MSG001': 'このPropertyはNormal Pageの項目に設定できません。',
    'SM440MSG002': 'EXCLUDEとSCRNUMは同じ項目に設定できません。',
    'SM440MSG003': 'SCRNUMはNormal Block以外の項目に設定できません。',
    'SM440MSG004': 'ENRNUMとSCRNUMは同じ項目に設定できません。',
    'SM440MSG005': '同じページに複数ENRNUMが設定できません。',
    'SM440MSG006': 'ENRNUMはTEXT以外の項目に設定できません。',
    'SM440MSG007': 'ENRNUMはNormal Block以外の項目に設定できません。',
    'SM440MSG008': 'Propertyを削除します。\n よろしいですか。',
    'SM440MSG009': '入力されたVISIT IDがすでに使用されています。',
    'SM440MSG010': 'Registrationが二つ以上のページで選択されています。',
    'SM440MSG011': '同じページに複数SCRNUMが設定できません。',
    'SM440MSG012': 'SCRNUMはTEXT以外の項目に設定できません。',
    'SM440MSG013': 'ePRO TRIGGERはDate (YYYY/MM/DD)以外の項目に設定できません。',
    'SM440MSG014': 'ePRO TRIGGERはNormal Block以外の項目に設定できません。',
    'SM440MSG015': 'このPropertyはLABELとROW NOの項目に設定できません。',
    'SM440MSG016': 'このPropertyはTEXTとTEXT AREA以外の項目に設定できません。',
    'SM440MSG017': 'このPropertyはLABEL、DATEまたTIMEの項目に設定できません。',
    'SM440MSG018': 'RANDOM_KEYは二つ以上の項目に設定できません。',
    'SM440MSG019': '一つのStratificationグループNo.は二つ以上の項目に設定できません。',
    'SM440MSG020': '複数のStratificationグループNo.は同じの項目に設定できません。',
    'SM440MSG021': '同じ項目に複数のRANDOM_KEYを設定できません。',
    'SM440MSG022': 'ARM_GENERATEとIP_GENERATEは同じ項目に設定できません。',
    'SM440MSG023': 'HIDDENとSCRNUMは同じ項目に設定できません。',
    'SM440MSG024': 'HIDDENとENRNUMは同じ項目に設定できません。',
    'SM440MSG025': 'STRATIFICATIONはNormal Block以外の項目に設定できません。',
    'SM440MSG026': 'CONSENT DATEはDATE以外の項目に設定できません。',
    'SM440BTN001': 'CRFページ',
    'SM440LB001': 'バージョン',
    'SM440LB002': '入力不可設定',
    'SM440LB003': 'デフォルト値の設定',
    'SM440LB004': '項目の上に設定する単位',
    'SM440LB005': '項目の下に設定する単位',
    'SM440LB006': '項目の左に設定する単位',
    'SM440LB007': '項目の右に設定する単位',
    'SM440LB008': '指定したVisitのみ除外する',
    'SM440LB009': 'スクリーニング番号を入れる',
    'SM440LB010': 'Enroll 番号を入れる',
    'SM440LB011': 'Randomize Keyのコード値を設定する',
    'SM440LB012': '群情報を格納する',
    'SM440LB013': 'IP No.を格納する',
    'SM440LB014': 'Stratification Group No.を設定する',
    'SM440LB015': '隠し項目とする',
    'SM440LB020': '登録日付',
    'SM440LB021': 'ePROを有効にする',
    'SM440LB016': 'テキスト',
    'SM440LB017': 'VISIT ID',
    'SM440LB018': '数字',
    'SM440LB019': 'Y/N',
    'SM440LB022': 'CRF Group ID',
    'SM440LB023': 'Get data from the device',
    'SM440H001': 'Page ID',
    'SM440H002': 'Block ID',
    'SM440H003': 'item ID',
    'SM440H004': 'Property Type',
    'SM440H005': 'Property Data',
    'SM440H006': '削除',
    'SM440TT001': 'PROPERTY',
    'SM440OPT001': 'AUTOFILL',
    'SM440OPT002': 'DEFAULT',
    'SM440OPT003': 'UNIT_UPPER',
    'SM440OPT004': 'UNIT_BOTTOM',
    'SM440OPT005': 'UNIT_LEFT',
    'SM440OPT006': 'UNIT_RIGHT',
    'SM440OPT007': 'EXCLUDE',
    'SM440OPT008': 'SCRNUM',
    'SM440OPT009': 'ENRNUM',
    'SM440OPT010': 'RANDOM_KEY',
    'SM440OPT011': 'ARM_GENERATE',
    'SM440OPT012': 'IP_GENERATE',
    'SM440OPT013': 'STRATIFICATION',
    'SM440OPT014': 'HIDDEN',
    'SM440OPT015': 'CONSENT DATE',
    'SM440OPT016': 'ePRO TRIGGER',
    'SM440OPT017': 'GET_DEVICE_DATA',
});