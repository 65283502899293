module.exports = ({
    "SM430MSG002": "CONSENT_DATE設定済のページはページタイプが変更できません。",

    "SM431MSG006": "このブロックにSTRATIFICATIONがすでに設定されているため、ブロックの種別を変更できません。",
    "SM431MSG009": "このブロックにEnrollment numberがすでに設定されているため、ブロックの種別を変更できません。",

    'SM433P001': 'IDを入力してください',
    'SM433P002': 'Domain IDを入力してください',
    'SM433P003': 'ラベルを入力してください',
    'SM433MSG001': 'IDを入力してください。',
    'SM433MSG002': 'ラベルに項目名称を入力してください。',
    'SM433MSG003': 'CODE LISTを入力してください。',
    'SM433MSG004': '入力されたIDがすでに使用されています。',
    'SM433MSG011': 'CODE LISTに設定されているコード値の桁数より小さい値が設定されています。',
    'SM433MSG012': 'レイアウトの変更によりCDMSの入力データが削除される可能性があります。本当に変更しますか？',
    'SM433MSG013': 'CODE LISTに設定されているコード値が項目のデータタイプに交換できません。',
    'SM433MSG014': 'この項目にENROLLMENT NUMBERがすでに設定されているため、項目のレイアウトを変更できません。',
    'SM433MSG015': '同一DOMAINに同一のIDを設定することはできません。',
    'SM433MSG016': 'この項目にSCREENING NUMBERがすでに設定されているため、項目のレイアウトを変更できません。',
    'SM433MSG017': 'この項目にARM_GENERATEがすでに設定されているため、TEXTとTEXT AREA以外のレイアウトに変更できません。',
    'SM433MSG018': 'この項目にIP_GENERATEがすでに設定されているため、TEXTとTEXT AREA以外のレイアウトに変更できません。',
    'SM433MSG019': 'この項目にRANDOM_KEYがすでに設定されているため、LABELとROW NOのレイアウトに変更できません。',
    'SM433MSG020': 'この項目にSTRATIFICATIONがすでに設定されているため、LABEL、DATEまたTIMEのレイアウトに変更できません。',
    'SM433MSG021': 'この項目にCONSENT DATEがすでに設定されているため、DATE以外のレイアウトに変更できません。',
    'SM433MSG022': '最大値を最小値より大きい値で入力してください。',
    'SM433MSG023': 'この項目にGET_DEVICE_DATAがすでに設定されているため、TEXTとTEXT AREA以外のレイアウトに変更できません。',

    'SM433BTN001': 'Property',
    'SM433BTN002': 'プレビュー',
    'SM433LB001': 'バージョン',
    'SM433LB002': 'ID',
    'SM433LB003': 'Domain ID',
    'SM433LB004': 'Version',
    'SM433LB005': 'ラベル',
    'SM433LB006': 'レイアウト',
    'SM433LB007': 'CodeList',
    'SM433LB008': 'データタイプ',
    'SM433LB009': 'データの長さ',
    'SM433LB010': '必須項目',
    'SM433TT001': 'CRFページ',
    'SM433TT002': '項目作成',
    'SM433TT003': '項目編集 ({item_id_text} - {crf_version})',
    'SM433OPT001': '削除されたCRFを表示',
    'SM433OPT002': '文字',
    'SM433OPT003': '数字',
});